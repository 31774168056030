<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper" @click="hide">
        <div class="modal-container" @click.stop>
          <!-- <div class="modal-header">
            <slot name="header">
              <select v-model="srcAux" class="modal-header__select">
                <option
                  v-for="(item, index) in fileList"
                  :value="item"
                  :key="index"
                  v-text="item.title"
                ></option>
              </select>
              <input
                class="modal-header__numpage"
                v-model.number="page"
                type="number"
              />
              <p :style="{ color: '#fff', marginLeft: '5px' }">
                / {{ numPages }}
              </p>
              <button class="modal-header__rotate" @click="rotate += 90">
                &#x27F3;
              </button>
              <button class="modal-header__rotate" @click="rotate -= 90">
                &#x27F2;
              </button>
              <button class="modal-header__print" @click="$refs.pdf.print()">
                Imprimir
              </button>
            </slot>
          </div> -->

          <!-- <div class="modal-body"> -->
            <PDFViewer :fileName="fileName" :path="src" />
            <!-- <slot name="body" :style="{ 'overflow-y': 'scroll' }">
              <div
                v-if="loadedRatio > 0 && loadedRatio < 1"
                style="
                  background-color: green;
                  color: white;
                  text-align: center;
                "
                :style="{ width: loadedRatio * 100 + '%' }"
              >
                {{ Math.floor(loadedRatio * 100) }}%
              </div>
              <pdf
                ref="pdf"
                class="fileRead"
                :src="src"
                :page="page"
                :rotate="rotate"
                @password="password"
                @progress="loadedRatio = $event"
                @error="error"
                @num-pages="numPages = $event"
                @link-clicked="page = $event"
              ></pdf>
            </slot> -->
          <!-- </div> -->
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// import pdf from "vue-pdf";
import PDFViewer from "./PDFViewer.vue";

export default {
  name: "ReadFile",
  props: {
    fileList: Array,
  },
  components: {
    PDFViewer,
    // pdf: pdf,
  },
  watch: {
    srcAux(newValue) {
      this.$store.dispatch("file/setFileSelected", newValue);
    },
  },
  computed: {
    src() {
      return this.$store.getters["file/getFileSelected"].path;
    },
    fileName() {
      return this.$store.getters["file/getFileSelected"].title;
    }
  },
  data() {
    return {
      loadedRatio: 0,
      srcAux: "",
      page: 1,
      numPages: 0,
      rotate: 0,
    };
  },
  methods: {
    hide() {
      setTimeout(() => {
        this.$emit("close");
      }, 0);
    },
    password: function (updatePassword, reason) {
      console.log(reason);
      updatePassword(prompt('password is "test"'));
    },
    error: function (err) {
      console.log(err);
    },
  },
};
</script>

<style scoped>
.modal-mask {
  display: table;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow: hidden;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  /*text-align: center;*/
  position: relative;
}

.modal-container {
  max-width: 70%;
  height: 95%;
  margin: 5% auto;
  overflow: auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  z-index: 10000;
}
.fileRead {
  border: 1px solid #394c7e;
  max-height: 500px;
}

.modal-header {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #394c7e;
}

.modal-header__select {
  width: 50%;
  padding: 0.5rem 0;
  cursor: pointer;
}

.modal-header__numpage {
  font-size: 1rem;
  margin-left: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0;
  width: 5%;
}

.modal-header__rotate {
  padding: 0.4rem;
  margin-left: 1rem;
  font-size: 1rem;
  cursor: pointer;
}

.modal-header__print {
  margin-left: 1.5rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.modal-body {
  margin: 20px 0;
  height: 80%;
}

/**

**/

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem 0;
}

.modal-default-button {
  float: right;
  padding: 0.6rem;
  background-color: #25304c;
  border-radius: 4px;
  color: white;
  font-size: 1rem;
  width: 25%;
  transition: all 0.2s ease-in;
}

.modal-default-button:hover {
  cursor: pointer;
  background-color: #394c7e;
}

.modal-default-button:disabled {
  background-color: #394c7e;
  color: lightgray;
  cursor: not-allowed;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

@media screen and (min-width: 0px) and (max-width: 580px) {
  .modal-container {
  max-width: 85%;
  height: 95%;
  margin: 5% auto;
  overflow: auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  z-index: 10000;
}
}
</style>
