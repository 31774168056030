<template>
  <nav id="navbar">
    <button id="btn-add-files" @click="handleAddFile" :class="showAddButton ? `button-mobile` : `button-desktop`">Adicionar Arquivo</button>
    <div class="navbar__options">
      <Button class="navbar__btn mobile-button" @click.native="handleAddFile">
        <span class="material-icons-outlined">
          upload_file
        </span>
      </Button>
      <!-- <Button class="navbar__btn" @click.native="help = true">
        <span
          class="material-icons navbar__buttons-actions"
          title="Configurações"
        >
          settings
        </span>
      </Button> -->
      <Button class="navbar__btn" v-if="edit" @click.native="deleteManyFiles">
        <span
          class="material-icons navbar__buttons-actions"
          title="Deletar arquivos"
        >
          delete
        </span>
      </Button>
      <Button class="navbar__btn" @click.native="handleEdit">
        <span
          class="material-icons navbar__buttons-actions"
          title="Editar Lote"
        >
          edit
        </span>
      </Button>
      <Button class="navbar__btn" @click.native="handlerLogout">
        <span
          class="material-icons navbar__buttons-actions"
          title="Fazer Logout"
        >
          logout
        </span>
      </Button>
    </div>
  </nav>
</template>

<script>
import Button from "../../components/NavigationBar/Button.vue";
export default {
  components: {
    Button,
  },
  data() {
    return {
      showAddButton : false,
    };
  },
  mounted() {
    window.addEventListener("unload", this.clearStateOnReload);
  },
  computed: {
    edit() {
      return this.$store.getters["file/getEditMode"];
    },
  },
  methods: {
    clearStateOnReload() {
      this.$store.dispatch("file/clearFileDelete");
      this.$store.dispatch("file/setEditMode", false);
    },
    handleAddFile() {
      this.$emit("openModalFileAdd", true);
    },
    handlerLogout() {
      localStorage.removeItem("token");
      this.$store.dispatch("auth/clearUser");
      this.$router.push("/");
    },
    handleEdit() {
      let editmode = this.$store.getters["file/getEditMode"];
      this.$store.dispatch("file/setEditMode", !editmode);
    },
    async deleteManyFiles() {
      var files_delete = this.$store.getters["file/getFilesDelete"];

      for (let file of files_delete) {
        try {

          const fileForm = {
            _id: file._id,
            fid_lote: file.fid_lote,
          }

          const response = await this.$fileService.deleteFile(fileForm);

          if (response.status === 200) {
            this.$toasted.success("Arquivo deletado com sucesso!", {
              icon: "check",
              duration: 2000,
            });
            if (file.file_format.includes("pdf")) {
              this.$store.dispatch("file/removeFileList", {
                _id: file._id,
                state: "files",
              });
            } else {
              this.$store.dispatch("file/removeFileList", {
                _id: file._id,
                state: "images",
              });
            }
          }
        } catch (error) {
          this.$toasted.error("Erro ao deletar arquivos!", {
            duration: 2000,
          });
        }
      }

      this.$store.dispatch("file/clearFileDelete");
      this.$store.dispatch("file/setEditMode", false);
    },
  },
};
</script>

<style scoped>
#navbar {
  height: 10%;
  margin-top: 1rem;
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar__options {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.navbar__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin: 0;
  margin-left: 1rem;
}

.navbar__buttons-actions {
  font-size: 1.8rem;
}

#btn-add-files {
  width: 173px;
  height: 40px;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
  color: #ffffff;
  background: #25304c;
  border-radius: 4px;
}

#btn-add-files:hover {
  filter: brightness(0.8);
}

.mobile-button{
  display: none;
}
@media screen and (min-width: 0px) and (max-width: 580px) {
  .navbar__options {
    width: 25%;
    display: flex;
    margin-left: 75%;
}
  .button-desktop{
    display: none !important;
  }
  .button-mobile{
    display: block;
  }
  .mobile-button{
    display: block;
      
  }
  .navbar__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin: 0;
  margin-left: 1rem;
  z-index: 40;
}
}
@media screen and (max-width: 320px) and (max-height: 568px) {

  .navbar__options {
    width: 25%;
    display: flex;
    margin-left: 75%;
}
  .button-desktop{
    display: none !important;
  }
  .button-mobile{
    display: block;
  }
  .mobile-button{
    display: block;
      
  }
  .navbar__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin: 0;
  margin-left: 0.5rem;
  z-index: 40;
}

}
</style>