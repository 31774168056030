<template>
  <div id="bar-content">
    <div id="bar-content__head">Itasol</div>
    <div id="bar-content__list">
      <List :loteamentos="loteamentos" />
    </div>
    <div id="bar-content__home">
      <span
        class="material-icons bar-content__buttons-actions"
        title="Voltar ao inicio"
        @click="$router.push('/home')"
      >
        home
      </span>
    </div>
  </div>
</template>

<script>
import List from "./List.vue";
export default {
  props: {
    loteamentos: Array,
  },
  components: {
    List,
  },
};
</script>

<style scoped>
#bar-content {
  position: fixed;
  width: 10vw;
  height: 100vh;
  background: #f8f8f8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 4;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
}

#bar-content__head {
  background: #25304c;
  color: white;
  height: 15%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#bar-content__list {
  width: 100%;
  height: 80%;
}

#bar-content__home {
  height: 15%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bar-content__buttons-actions {
  background: #e5e5e5;
  padding: 0.8rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #25304c;
  font-size: 1.8rem;
}

.bar-content__buttons-actions:hover {
  cursor: pointer;
  filter: brightness(0.8);
}

@media screen and (min-width: 0px) and (max-width: 580px) {
  #bar-content{
    width: 20%;
    height: 100%;
    position: fixed;
    z-index: 100;
  }
}
</style>