<template>
  <div id="details" v-if="fileList.length > 0">
    <FileCard
      v-for="(item, index) in fileList"
      :key="index"
      :item="item"
      :edit="edit"
      :lote="lote"
      @openModalFileRead="openModalFileRead"
    />
  </div>
  <div v-else id="details-empty">
    <h1>Não há arquivos de texto para esse lote!</h1>
  </div>
</template>

<script>
import FileCard from "./FileCard.vue";
export default {
  props: { fileList: Array, lote: Object, edit: Boolean },
  components: {
    FileCard,
  },
  methods: {
    openModalFileRead(value) {
      this.$emit("openModalFileRead", value);
    },
  },
};
</script>

<style scoped>
#details {
  width: 95%;
  height: 35vh;
  margin: 2rem 0;
  background: #fcfcfc;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
  overflow-y: scroll;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 35%;
}

#details-empty {
  width: 95%;
  height: 35vh;
  margin-top: 2rem;
  margin: 2rem 0;
  background: #fcfcfc;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 600;
}
</style>