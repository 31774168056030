<template>
  <div id="file-card">
    <input
      type="checkbox"
      v-if="edit"
      v-model="delSelect"
      @change="check($event)"
    />
    <div id="file-card__body" @click="handleOpenFile">
      <span class="material-icons" v-if="item.file_format.includes('video')">
        video_library
      </span>
      <span
        class="material-icons"
        title="Arquivo de Imagem"
        v-else-if="item.file_format.includes('image')"
      >
        photo
      </span>
      <span class="material-icons" title="Arquivo PDF" v-else>
        picture_as_pdf
      </span>
      <h3>{{ item.title }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  props: { item: Object, edit: Boolean, lote: Object },
  data() {
    return {
      delSelect: false,
    };
  },
  watch: {
    edit(newEdit) {
      if (!newEdit) {
        this.delSelect = false;
      }
    },
  },
  methods: {
    handleOpenFile() {
      this.$store.dispatch("file/setFileSelected", this.item);
      this.$emit("openModalFileRead", true);
    },
    check() {
      if (this.delSelect) {
        this.$store.dispatch("file/setFilesDelete", {
          _id: this.item._id,
          fid_lote: this.lote.fid,
          file_format: this.item.file_format,
        });
        // console.log("DELETE: ", this.item._id);
      } else {
        this.$store.dispatch("file/removeFileDelete", this.item._id);
        // console.log("NOT DELETE: ", this.item._id);
      }
    },
  },
};
</script>

<style scoped>
#file-card {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#file-card > input {
  padding: 1.5rem;
}

#file-card__body {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 70%;
  height: 80%;
  transition: all 0.2s ease-in;
}

#file-card__body:hover {
  cursor: pointer;
  background: #c6c6c6;
}

#file-card__body > h3 {
  color: #25304c;
  font-weight: 600;
}

#file-card__body > span {
  color: #25304c;
  margin: 0 1rem;
  font-size: 2rem;
}
</style>