<template>
  <div id="container-file">
    <Mobilebar id="mobile-bar" @openLateralBar="handleShowSideBar" />
    <LateralBar
      :loteamentos="loteamentos"
      :class="
        showLateralBarMobile ? `lateral-bar-mobile` : `lateral-bar-desktop`
      "
      v-click-outside="handleBarOutside"
    />
    <!-- <div id="container-file__body"> -->
    <Edit :lotes="loteamentos" @reload="reload" />
    <!-- </div> -->
  </div>
</template>

<script>
import LateralBar from "../../components/LateralBarFile/LateralBar.vue";
import Edit from "../../components/Files/Edit.vue";
import Mobilebar from "../../components/NavigationBar/MobileBar";

export default {
  data() {
    return {
      showLateralBarMobile: false,
      loteamentos: [],
    };
  },
  components: {
    LateralBar,
    Edit,
    Mobilebar,
  },
  // computed: {
  //   lotes() {
  //     return this.$store.getters["mapa/getLotes"];
  //   },
  // },
  created() {
    this.$store.dispatch("mapa/setLoaderState", true);
    this.loadLotes();
    // setTimeout(() => {
    this.$store.dispatch("mapa/setLoaderState", false);
    // }, 5000);
  },
  methods: {
    reload() {
      this.loadLotes();
    },
    handleShowSideBar() {
      this.showLateralBarMobile = true;
      // console.log(this.showLateralBarMobile);
    },
    handleBarOutside() {
      this.showLateralBarMobile = false;
    },
    async loadLotes() {
      try {
        this.$store.dispatch("mapa/setLoaderState", true);
        const token = localStorage.getItem("token");
        const config = { headers: { Authorization: `Bearer ${token}` } };
        /* Carregar lista de lotes */
        // this.loteamentos = this.$fileService.createList(this.lotes);
        // Load initial Files PDF
        const response = await this.$http.get("loteamentos/list", config);
        this.loteamentos = this.$fileService.createList(
          response.data.loteamentos
        );
        this.$store.dispatch("file/setLote", this.loteamentos[0]);
        //Load initial Files PDF
        this.$fileService
          .loadFiles(this.loteamentos[0].fid, "pdf")
          .then((res) => {
            this.$store.dispatch("file/setFileList", res);
          });
        // load initial Files Images
        this.$fileService
          .loadFiles(this.loteamentos[0].fid, "image")
          .then((res) => {
            this.$store.dispatch("file/setImageList", res);
          });
        this.$store.dispatch("mapa/setLoaderState", false);
      } catch (error) {
        console.log(error);
      }

      // this.$fileService
      //   .loadFiles(this.loteamentos[0].fid, "pdf")
      //   .then((res) => {
      //     this.$store.dispatch("file/setFileList", res);
      //   });
      // // load initial Files Images
      // this.$fileService
      //   .loadFiles(this.loteamentos[0].fid, "image")
      //   .then((res) => {
      //     this.$store.dispatch("file/setImageList", res);
      //   });
      // this.$http
      //   .get("loteamentos/list", config)
      //   .then((res) => {
      //     this.loteamentos = this.$fileService.createList(res.data.loteamentos);
      //     this.$store.dispatch("file/setLote", this.loteamentos[0]);
      //     //Load initial Files PDF
      //     this.$fileService
      //       .loadFiles(this.loteamentos[0].fid, "pdf")
      //       .then((res) => {
      //         this.$store.dispatch("file/setFileList", res);
      //       });
      //     // load initial Files Images
      //     this.$fileService
      //       .loadFiles(this.loteamentos[0].fid, "image")
      //       .then((res) => {
      //         this.$store.dispatch("file/setImageList", res);
      //       });
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },
  },
};
</script>

<style scoped>
#container-file {
  display: flex;
  width: 100%;
  height: 100%;
}
#mobile-bar {
  margin-top: 2%;
  display: none;
}

/* #container-file__body {
  width: 100%;
  height: 100%;
} */

@media screen and (min-width: 0px) and (max-width: 580px) {
  .lateral-bar-desktop {
    display: none !important;
  }
  .lateral-bar-mobile {
    display: block;
  }
  #mobile-bar {
    display: block;
    z-index: 1;
  }
}
</style>
