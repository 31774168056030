<template>
  <div id="carousel" v-if="imageList.length > 0">
    <button class="carousel__btn" @click="prev">
      <span class="material-icons-outlined"> arrow_back_ios </span>
    </button>
    <div class="carousel__content">
      <div class="carousel__inner" ref="inner" :style="innerStyles">
        <div
          class="carousel__card"
          v-for="(card, index) in imageList"
          :key="index"
          draggable
          @dragover.prevent
          @dragstart="dragStart($event)"
          @dragover="dragOver($event)"
        >
          <ExpandableMidia
            class="carousel__card-expandable"
            :video="card.file_format === 'video/mp4'"
            :src="card.path"
            :alt="card.title"
            :title="card.title"
          />
          <!-- <expandable-image
            class="carousel__card-expandable"
            :src="card.path"
            :alt="card.title"
            :title="card.title"
          ></expandable-image> -->
        </div>
      </div>
    </div>
    <button class="carousel__btn" @click="next">
      <span class="material-icons-outlined"> arrow_forward_ios </span>
    </button>
  </div>
  <div v-else id="carousel__empty">
    <h1>Não há imagens para esse lote!</h1>
  </div>
</template>

<script>
import ExpandableMidia from "./ExpandableMidia.vue";
export default {
  name: "Carousel",
  props: { imageList: Array },
  components: { ExpandableMidia },
  data() {
    return {
      positionInitial: "",
      // imageList: [
      //   { path: "/img/image1.jpg" },
      //   { path: "/img/image2.jpg" },
      //   { path: "/img/image3.png" },
      //   { path: "/img/image4.jpg" },
      // ],
      innerStyles: {},
      step: "",
      transitioning: false,
    };
  },
  mounted() {
    this.setStep();
    this.resetTranslate();
  },
  methods: {
    dragStart(e) {
      this.positionInitial = e.clientX;
    },
    dragOver(e) {
      if (e.clientX > this.positionInitial) {
        setTimeout(() => {
          this.prev();
        }, 250);
      } else {
        setTimeout(() => {
          this.next();
        }, 250);
      }
    },
    setStep() {
      // const innerWidth = this.$refs.inner.scrollWidth;
      // const totalCards = this.imageList.length;
      // console.log(innerWidth, totalCards);
      // this.step = `${innerWidth / totalCards}px`;
      this.step = "1px";
    },
    next() {
      if (this.transitioning) return;
      this.transitioning = true;
      this.moveLeft();
      this.afterTransition(() => {
        const card = this.imageList.shift();
        this.imageList.push(card);
        this.resetTranslate();
        this.transitioning = false;
      });
    },
    prev() {
      if (this.transitioning) return;
      this.transitioning = true;
      this.moveRight();
      this.afterTransition(() => {
        const card = this.imageList.pop();
        this.imageList.unshift(card);
        this.resetTranslate();
        this.transitioning = false;
      });
    },
    moveLeft() {
      this.innerStyles = {
        transform: `translateX(-${this.step})
                    translateX(-${this.step})`,
      };
    },
    moveRight() {
      this.innerStyles = {
        transform: `translateX(${this.step})
                    translateX(-${this.step})`,
      };
    },
    afterTransition(callback) {
      const listener = () => {
        callback();
        this.$refs.inner.removeEventListener("transitionend", listener);
      };
      this.$refs.inner.addEventListener("transitionend", listener);
    },
    resetTranslate() {
      this.innerStyles = {
        transition: "none",
        transform: `translateX(-${this.step})`,
      };
    },
  },
};
</script>

<style>
#carousel {
  width: 95%;
  height: 36vh;
  background: #fcfcfc;
  padding: 2rem 0;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
}

#carousel__empty {
  width: 95%;
  height: 36vh;
  background: #fcfcfc;
  font-size: 1.5rem;
  font-weight: 600;
  padding: 2rem 0;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
}

#carousel_editmode {
  width: 95%;
  height: 36vh;
  background: #fcfcfc;
  font-size: 1.5rem;
  font-weight: 600;
  padding: 2rem 0;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
}

.carousel__content {
  width: 100%;
  overflow: hidden;
}
.carousel__inner {
  transition: transform 0.2s;
  white-space: nowrap;
  margin-left: 1rem;
}
.carousel__card {
  height: 250px;
  width: 350px;
  margin-right: 10px;
  display: inline-flex;
  /* optional */
  border-radius: 4px;
  align-items: center;
  justify-content: center;
}

.carousel__card-expandable {
  height: 100%;
  width: 100%;
}

.carousel__card-expandable > img {
  height: 100%;
  width: 100%;
}

.carousel__card-expandable:hover {
  cursor: pointer;
}

/* optional */
.carousel__btn {
  margin: 0 2rem;
  padding: 0.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: #25304c;
  cursor: pointer;
  transition: all 0.2s ease;
}

.carousel__btn > span {
  font-size: 1rem;
}

.carousel__btn:hover {
  background: #405a9e;
}

@media screen and (min-width: 0px) and (max-width: 580px) {
  .carousel__card {
    height: 200px;
    width: 100%;
    /* margin-right: 50px; */
    /* margin-bottom: 60px; */
  }
  .carousel__btn {
    margin: 0 1rem;
  }
}
</style>
