<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper" @click="hide">
        <div class="modal-container" @click.stop>
          <div class="modal-header">
            <slot name="header">
              <h1>Adicionar Arquivo</h1>
            </slot>
          </div>
          <form id="form-user" @submit.prevent="submit">
            <div class="modal-body">
              <slot name="body">
                <div id="title-form">
                  <label for="title">Nome do Arquivo</label>
                  <input
                    type="text"
                    name="title"
                    placeholder="Casa de Praia"
                    v-model="fileTitle"
                  />
                </div>
                <hr class="solid" />
                <div
                  @drop="onDrop"
                  @dragover.prevent
                  @dragenter.prevent="fileOverDropArea = true"
                  @dragleave.prevent="fileOverDropArea = false"
                  :class="{
                    'drop-over': fileOverDropArea,
                    drop: !fileOverDropArea,
                    'drop-error': dropError,
                  }"
                >
                  <div class="helper"></div>
                  <label v-if="!image && !dropError" class="btn">
                    SELECIONE OU ARRASTE UM ARQUIVO
                    <input
                      type="file"
                      name="image"
                      @change="onChange"
                      :maxlength="40 * 1024 * 1024"
                    />
                  </label>
                  <div class="hidden" v-else v-bind:class="{ image: true }">
                    <img
                      v-if="fileType == `image` && !dropError"
                      :src="image"
                      alt="Imagem"
                      class="img"
                    />
                    <h1
                      v-else-if="fileType !== `image` && !dropError"
                      id="filename-title"
                    >
                      {{ filename }}
                    </h1>
                    <br />
                    <br />
                    <button class="modal-default-button" id="errorMobile" @click="removeFile">
                      REMOVER
                    </button>
                  </div>
                  <h3 v-if="!image && !dropError" id="description">
                    Os tipos de arquivos aceitos são: <br />
                    .pdf | .jpg | .jpeg | .png <br />
                    <br />
                    Tamanho Máximo: 40MB
                  </h3>
                  <h3 v-if="dropError" id="error-description">
                    Desculpe, este tipo de arquivo <br />
                    não é suportado. :( <br />
                    <br />
                    Ou o tamanho excedeu o limite de 40MB.
                  </h3>
                </div>
              </slot>
            </div>

            <div class="modal-footer">
              <slot name="footer">
                <button
                  :disabled="!formIsValid"
                  type="submit"
                  class="modal-default-button"
                >
                  Salvar
                </button>
              </slot>
            </div>
          </form>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ModalAddFile",
  props: { lote: Object },
  data() {
    return {
      image: "",
      filename: "",
      fileType: "",
      file: "",
      fileTitle: "",
      fileOverDropArea: false,
      dropError: false,
    };
  },
  computed: {
    formIsValid() {
      return this.file && this.fileTitle;
    },
  },
  methods: {
    onDrop: function (e) {
      this.dropError = false;
      e.stopPropagation();
      e.preventDefault();
      var files = e.dataTransfer.files;
      this.createFile(files[0]);
    },
    onChange(e) {
      var files = e.target.files;
      this.createFile(files[0]);
    },
    createFile(file) {
      if (!file.type.match('image.*|application.*|video.*')) {
        this.dropError = true;
        return;
      }

      var reader = new FileReader();
      var vm = this;

      this.file = file;
      this.filename = file.name;

      if (file.type.includes("image")) {
        this.fileType = "image";
      } else {
        this.fileType = "application";
      }

      reader.onload = function (e) {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    removeFile() {
      this.image = "";
      this.file = "";
      this.filename = "";
      this.fileType = "";
      this.dropError = false;
      this.fileOverDropArea = false;
    },
    hide() {
      setTimeout(() => {
        this.$emit("close");
      }, 0);
    },
    async submit() {
      try {
        const fileForm = new FormData();
        fileForm.append("file", this.file);
        fileForm.append("fid_lote", this.lote.fid);
        fileForm.append("title", this.fileTitle);

        const response = await this.$fileService.uploadFile(fileForm);

        if (response.status === 200) {
          this.$toasted.success("Arquivo carregado com sucesso!", {
            icon: "check",
            duration: 2000,
          });
          const fileList = await this.$fileService.loadFiles(this.lote.fid, 'pdf');
          this.$store.dispatch('file/setFileList', fileList);
          
          const imageList = await this.$fileService.loadFiles(this.lote.fid, 'image');
          this.$store.dispatch('file/setImageList', imageList);
        }

        this.$emit("close");
      } catch (error) {
        this.$toasted.error("Erro ao carregar arquivo, tente novamente!", {duration: 2000});
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 60%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  z-index: 10000;
}

.modal-header h1 {
  margin-top: 0;
  padding: 1rem 0;
  color: #25304c;
  font-weight: bold;
  text-align: center;
  font-size: 2rem;
}

.modal-body {
  margin: 20px 0;
}

/**/
#title-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem 0;
}

#title-form > label {
  text-align: start;
  padding: 0.5rem 0;
  font-weight: 600;
}

#title-form > input {
  width: 100%;
  padding: 0.5rem 0;
  padding-left: 0.5rem;
  font-size: 1.2rem;
  background-color: #c4c4c4;
}

#filename-title {
  color: #394c7e;
  text-align: center;
  margin-top: 100px;
  font-weight: 600;
}

.btn {
  background-color: #25304c;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  width: 70%;
  padding: 1.2rem 0;
  position: relative;
}

.btn:hover {
  background-color: #394c7e;
}

input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

hr.solid {
  border-top: 1px solid #bbb;
  margin-bottom: 1rem;
}

.align-center {
  text-align: center;
}

.helper {
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  width: 0;
}

.hidden {
  display: flex !important;
  position: absolute;
  top: 0;
  left: 0;
}

.hidden.image {
  display: flex !important;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.img {
  border: 1px solid #f6f6f6;
  display: inline-block;
  height: auto;
  max-height: 70%;
  max-width: 80%;
  width: auto;
}

.drop {
  position: relative;
  background-color: #f2f2f2;
  border: 4px dashed #ccc;
  background-color: #f6f6f6;
  border-radius: 2px;
  height: 300px;
  max-height: 500px;
}

.drop-over {
  position: relative;
  background-color: #f2f2f2;
  border: 4px dashed #394c7e;
  background-color: #f6f6f6;
  border-radius: 2px;
  height: 300px;
  max-height: 500px;
}

.drop-error {
  position: relative;
  background-color: #f2f2f2;
  border: 4px dashed #ff0033;
  background-color: #f6f6f6;
  border-radius: 2px;
  height: 300px;
  max-height: 500px;
}

#description {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%);
  font-weight: 600;
  color: darkgray;
}

#error-description {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%);
  font-weight: 600;
  color: #ff0033;
}

/**/

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem 0;
}

.modal-default-button {
  float: right;
  padding: 0.6rem;
  background-color: #25304c;
  border-radius: 4px;
  color: white;
  font-size: 1rem;
  width: 25%;
  transition: all 0.2s ease-in;
}

.modal-default-button:hover {
  cursor: pointer;
  background-color: #394c7e;
}

.modal-default-button:disabled {
  background-color: #394c7e;
  color: lightgray;
  cursor: not-allowed;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
@media screen and (min-width: 0px) and (max-width: 580px) {
  .modal-container {
  width: 75%;
  padding: 0 30px;
}
  .modal-body {
  margin: 0;
}
  .helper {
  vertical-align:top;
}
  #description {
  top: 35%;
}

  #error-description {
  top: 5%;
}
  .drop {
  padding-top: 5%;
}

  .drop-over {
  padding-top: 5%;
}
  .modal-default-button {
  padding: 0.8rem;
  width: 60%;
}
  .modal-default-button:disabled {
  width: 50%;
}
  #errorMobile{
  margin-top: 80%;
  }

}
@media screen and (max-width: 320px) and (max-height: 568px) {

  #errorMobile{
  margin-top: 110%;
  }

}
</style>
