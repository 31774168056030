<template>
  <div id="details">
    <div id="details__wrapper">
      <div
        v-for="(lote, index) in lote.loteData"
        :key="index"
        :class="`field__default ` + `field__${lote.key}`"
      >
        <label :for="lote.key" class="details__label">{{ lote.label }}</label>
        <input
          v-if="lote.type === `text`"
          :name="lote.key"
          :type="lote.type"
          :class="edit ? `details__input` : `details__input-blocked`"
          :disabled="!edit"
          v-model="lote.value"
        />
        <textarea
          v-else-if="lote.type === `textarea`"
          :name="lote.key"
          cols="10"
          rows="6"
          :class="edit ? `details__input` : `details__input-blocked`"
          :disabled="!edit"
          v-model="lote.value"
        ></textarea>
        <select
          v-else-if="lote.type === `select`"
          :name="lote.key"
          v-model="lote.value"
          :disabled="!edit"
          :class="edit ? `details__input` : `details__input-blocked`"
        >
          <option
            v-for="(sit, index) in situations"
            :selected="sit.label === lote.color"
            :key="index"
            :value="sit.label"
            :style="{ background: `${sit.color}` }"
          >
            {{ sit.label }}
          </option>
        </select>
      </div>
    </div>
    <button v-if="edit" @click="submit" class="modal-default-button">
      Salvar Alteração
    </button>
  </div>
</template>

<script>
export default {
  props: ["lote", "lotes"],
  data() {
    return {
      situations: [
        {
          label: "ATRASADO",
          color: "#a00",
        },
        {
          label: "EM DIA",
          color: "#0a0",
        },
        {
          label: "ESCRITURA",
          color: "#fff",
        },
        {
          label: "ESTOQUE",
          color: "#aaa",
        },
        {
          label: "INDEFINIDO",
          color: "#d91",
        },
        {
          label: "QUITADO",
          color: "#00a",
        },
        {
          label: "RESERVADO",
          color: "#aa2",
        },
      ],
    };
  },
  computed: {
    edit() {
      return this.$store.getters["file/getEditMode"];
    },
  },
  methods: {
    async submit() {
      try {
        const response = await this.$fileService.updateLote(this.lote);
        if (response.status === 200) {
          // let lotesUpdated = await this.$loteService.updateLoteamento(
          //   this.lote,
          //   this.lotes
          // );
          // this.$store.dispatch("mapa/setLotes", lotesUpdated);
          // this.$toasted.success("Lote atualizado com sucesso!", {
          //   icon: "check",
          //   duration: 2000,
          // });
          this.$store.dispatch("file/setEditMode", false);
          this.$emit("reload");
        }
      } catch (error) {
        console.log(error);
        this.$toasted.error("Erro ao atualizar o lote!", {
          duration: 2000,
        });
      }
    },
  },
};
</script>

<style scoped>
#details {
  width: 95%;
  height: 100%;
  padding: 1rem 0;
  background: #fcfcfc;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: flex-start;
  margin-top: 1.5rem;
}

.modal-default-button {
  float: right;
  padding: 0.6rem;
  background-color: #25304c;
  border-radius: 4px;
  color: white;
  font-size: 1rem;
  width: 15%;
  margin: 0 auto;
  transition: all 0.2s ease-in;
}

.modal-default-button:hover {
  cursor: pointer;
  background-color: #394c7e;
}

#details__wrapper {
  width: 70%;
  height: 100%;
  margin-left: 2rem;

  display: grid;

  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
    "lote     area      gab"
    "quadra   situacao  observacao "
    "gleba    plano     observacao";
}

.field__default {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 0.5rem 0;
  margin-right: 1.5rem;
}

.field__lote {
  grid-area: lote;
}
.field__quadra {
  grid-area: quadra;
}
.field__gleba {
  grid-area: gleba;
}
.field__area {
  grid-area: area;
}
.field__situacao {
  grid-area: situacao;
}
.field__plano {
  grid-area: plano;
}
.field__gab {
  grid-area: gab;
}
.field__observacao {
  grid-area: observacao;
}

.field__observacao > textarea {
  resize: none;
}

.details__label {
  color: #25304c;
  padding: 0.5rem 0;
  font-weight: 600;
}

.details__input {
  background: #c4c4c4;
  padding: 0.5rem 0.5rem;
  font-size: 1rem;
}

.details__input-blocked {
  background: #c4c4c4;
  padding: 0.5rem 0.5rem;
  font-size: 1rem;
  cursor: not-allowed;
}

@media screen and (min-width: 720px) and (max-width: 1060px) {
  #details__wrapper {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "lote     area "
      "gab      quadra"
      "situacao  gleba"
      "plano     observacao";
  }
  .modal-default-button {
    width: 18%;
  }
}

@media screen and (min-width: 580px) and (max-width: 720px) {
  #details__wrapper {
    grid-template-columns: 1.5fr 1.5fr;
    grid-template-areas:
      "lote     area "
      "gab      quadra"
      "situacao  gleba"
      "plano     observacao";
  }
  .modal-default-button {
    display: flex;
    justify-content: center;
    margin-left: 30px;
    width: 38%;
  }

  #details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}

@media screen and (min-width: 0px) and (max-width: 580px) {
  #details__wrapper {
    grid-template-columns: 1fr;
    grid-template-areas:
      "lote"
      "area"
      "quadra"
      "gab"
      "situacao"
      "gleba"
      "plano"
      "observacao";
    overflow: auto;
    height: 30%;
    overflow-x: hidden;
  }
  .modal-default-button {
    display: flex;
    justify-content: center;
    margin-top: 2%;
    width: 38%;
  }
  #details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .field__default {
    padding: 0.2rem;
    padding: 0.2rem 0;
    margin-right: 0.5rem;
  }
}
</style>
