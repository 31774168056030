<template>
  <div :id="id">
    <div id="card-lote-file__line" :style="`background: ${color}`"></div>
    <div id="card-lote-file__body">
      <h2>{{ title }}</h2>
    </div>
  </div>
</template>

<script>
import { colors } from "../../utils/colors";
export default {
  props: {
    title: String,
    sit: String,
    id: String,
  },
  computed: {
    color() {
      return colors[this.sit] || "#d91";
    },
  },
  data() {
    return {
      subtitles: [
        {
          label: "ATRASADO",
          color: "#a00",
        },
        {
          label: "EM DIA",
          color: "#0a0",
        },
        {
          label: "ESCRITURA",
          color: "#fff",
        },
        {
          label: "ESTOQUE",
          color: "#aaa",
        },
        {
          label: "INDEFINIDO",
          color: "#d91",
        },
        {
          label: "QUITADO",
          color: "#00a",
        },
        {
          label: "RESERVADO",
          color: "#aa2",
        },
      ],
    };
  },
};
</script>

<style scoped>
#card-lote-file {
  width: 90%;
  background-color: #e5e5e5;
  margin: 0.5rem 0;
  transition: all 0.2s ease-in;
}

#card-lote-file-selected {
  width: 90%;
  background-color: #e5e5e5;
  filter: brightness(0.8);
  margin: 0.5rem 0;
  transition: all 0.2s ease-in;
}

#card-lote-file:hover {
  cursor: pointer;
  filter: brightness(0.8);
}

#card-lote-file__line {
  height: 0.3rem;
  width: 45%;
}

#card-lote-file__body {
  color: #25304c;
  padding: 1rem 0;
  font-weight: bold;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}

#card-lote-file__body > h2 {
  margin-left: 0.8rem;
}
</style>