<template>
  <!-- <div> -->
  <!-- <embed :src="`${getFilePath}`" height="100%" width="100%"/> -->
  <iframe height="100%" width="100%" :src="`${getFilePath}`"></iframe>
  <!-- </div> -->
</template>

<script>
export default {
  name: "PDFViewer",
  props: {
    fileName: String,
    path: String,
  },
  computed: {
    getFilePath() {
      // if (this.fileName !== "") {
      //   return this.path + "?file=" + this.fileName;
      // }
      console.log("path: ", this.path);
      return this.path;
    },
  },
};
</script>
<style scoped>
div {
  width: 100%;
  height: 85vh;
}
</style>