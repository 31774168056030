<template>
  <div id="list-lote-file">
    <Card
      v-for="lote in loteamentos"
      :key="lote.id"
      :title="lote.fid !== undefined ? `${lote.fid}` : `Lote`"
      :sit="lote.color"
      :id="
        lote.id === current_selected.id
          ? `card-lote-file-selected`
          : `card-lote-file`
      "
      v-on:click.native="selectedLote(lote)"
    />
  </div>
</template>

<script>
import Card from "./Card.vue";
export default {
  props: {
    loteamentos: Array,
  },
  components: {
    Card,
  },
  // data() {
  //   return {
  //     // current_selected: this.$store.getters["file/getLote"],
  //   };
  // },
  computed: {
    current_selected() {
      return this.$store.getters["file/getLote"];
    }
  },
  methods: {
    async selectedLote(lote) {
      // Set lateral bar lote selected and content values
      this.$store.dispatch('file/setLote', lote);

      // Load files of a lote
      // this.current_selected = this.$store.getters["file/getLote"];
      const fileList = await this.$fileService.loadFiles(lote.fid, 'pdf');
      this.$store.dispatch('file/setFileList', fileList);

      //Load images of a lote
      const imageList = await this.$fileService.loadFiles(lote.fid, 'image');
      this.$store.dispatch('file/setImageList', imageList);
      
      // Clears edit and delete list states when selecting another lote
      this.$store.dispatch("file/clearFileDelete");
      this.$store.dispatch("file/setEditMode", false);
    },
  },
};
</script>

<style scoped>
#list-lote-file {
  width: 100%;
  height: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1rem;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  background: #e6e6e6;
}
::-webkit-scrollbar-thumb {
  background: #c5c5c5;
  border-radius: 4px;
}

#list-lote-file-empty {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}
</style>