<template>
  <div id="edit-container">
    <ModalReadFile
      v-if="openModalRead"
      @close="onCloseReadFile"
      :fileList="fileList"
    />
    <ModalAddFile
      v-if="openModalAdd"
      @close="openModalAdd = false"
      :lote="lote"
    />
    <Navbar @openModalFileAdd="openModalFileAdd" />
    <Details :lote="lote" :lotes="lotes" @reload="$emit('reload')"/>
    <Carousel :imageList="imageList" v-if="!edit" />
    <FilesList :fileList="imageList" :edit="edit" :lote="lote" v-else />
    <FilesList
      @openModalFileRead="openModalFileRead"
      :edit="edit"
      :lote="lote"
      :fileList="fileList"
    />
  </div>
</template>

<script>
import Navbar from "./Navbar.vue";
import Details from "./Details.vue";
import FilesList from "./FilesList.vue";
import ModalAddFile from "./ModalAddFile.vue";
import ModalReadFile from "./ModalReadFile.vue";
import Carousel from "./Carousel.vue";
export default {
  props: ['lotes'],
  computed: {
    lote() {
      return this.$store.getters["file/getLote"];
    },
    fileList() {
      return this.$store.getters["file/getFileList"];
    },
    imageList() {
      return this.$store.getters["file/getImageList"];
    },
    edit() {
      return this.$store.getters["file/getEditMode"];
    },
  },
  data() {
    return {
      openModalAdd: false,
      openModalRead: false,
    };
  },
  components: {
    Navbar,
    Details,
    ModalAddFile,
    ModalReadFile,
    Carousel,
    FilesList,
  },
  methods: {
    openModalFileAdd(value) {
      this.openModalAdd = value;
    },
    openModalFileRead(value) {
      this.openModalRead = value;
    },
    onCloseReadFile() {
      this.openModalRead = false;
    },
  },
};
</script>

<style scoped>
#edit-container {
  background-color: #f5f5f5;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  margin-left: 10%;
}

@media screen and (min-width: 0px) and (max-width: 580px) {
  #edit-container {
  margin-left: 0%;
}
}

</style>